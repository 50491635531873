import { Component, OnInit, Input, inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { GenericDialogComponent } from '../../../../shared/generic-dialog/generic-dialog.component';
import {PostsService} from '../../../../services/posts/posts.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PostEditDialogComponent } from 'src/app/shared/post-edit-dialog/post-edit-dialog.component';

@Component({
  selector: 'content-event-preview-post',
  templateUrl: './event-preview-post.component.html',
  styleUrls: ['./event-preview-post.component.scss']
})
export class EventPreviewPostComponent implements OnInit {
  @Input()
  calendarData;
  @Input() content?: any;
  posts;

  showDelete = false;
  platformsNotSupport = ['instagram', 'tiktok', 'google_business'];
  sanitizedContent: SafeHtml;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  isShowSlide = false;

  cDate = new Date();
  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private postsService: PostsService,
    private nzMessageService: NzMessageService,
    private sanitizer: DomSanitizer,
  ) {

  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 120;
  };

  handleDate(date) {
    if (differenceInCalendarDays(date, new Date()) > 120) {
      this.message.create('error', 'You cannot select date after 4 months!');
      this.cDate = new Date();
    }
  }
  ngOnInit() {
    this.content = this.nzModalData.content
    if (this.cDate.getUTCDate() > 25) {
      this.cDate = new Date(this.cDate.getFullYear(), this.cDate.getUTCMonth() + 1, 1);
    }
    this.updateSanitizedContent();

    this.checkShowSlide();
    
  }
  get calnderDataKeys() {
    return Object.keys(this.calendarData || [])
  }

  handleDeletePost() {
    const isNoSupport = !this.platformsNotSupport.includes(this.content.post.posted_on);
    const message = isNoSupport
    ? `This action will delete the post our platform.\n\n If this post was published on ${this.content.post.posted_on.toUpperCase()}, it will also be deleted from there.\n\nAre you sure you want to proceed?`
    : `This action will ONLY delete the post from our platform cause platform (${this.content.post.posted_on.toUpperCase()}) does not support deleting posts directly.\n\n To delete the post on ${this.content.post.posted_on.toUpperCase()}, please log in to your ${this.content.post.posted_on.toUpperCase()} account and remove it manually.\n\n Are you sure you want to proceed?`;
  
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Yes',
            value: true,
            color: 'warn',
          },
        ],
      },
      nzFooter: null,
      nzWidth: '60%',
    });

    modal.afterClose.subscribe((result) => {
      if (result) {
        const postId = this.content.post.id;
  
        if (isNoSupport) {
          // Handle both social and platform deletion
          this.postsService.deletePostSocial(postId).subscribe(
            (res) => {
              this.nzMessageService.success('Post deleted from social media and platform successfully!');
              this.onModalClosed();
            },
            (error) => {
              console.error('Error deleting post', error);
              this.nzMessageService.error('Failed to delete the post. Please try again.');
            }
          );
        } else {
          // Handle platform-only deletion
          this.postsService.deletePost(postId).subscribe(
            (res) => {
              this.nzMessageService.success('Post deleted from our platform successfully!');
              this.onModalClosed();
            },
            (error) => {
              console.error('Error deleting post', error);
              this.nzMessageService.error('Failed to delete the post. Please try again.');
            }
          );
        }
      }
    });
  }

  onModalClosed(): void {
    this.showDelete = false;
    this.close();
  }

  close(){
    this.modalService.closeAll();
  }

  checkSocial(social){
    if(this.content.post.posted_on === social){
      return true;
    }
    return false;
  }

  formatDate(dateString: string): string {
    if (!dateString || dateString === null) {
      return '---';
    }
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  formatTime(timeString: string): string {
    if (!timeString || timeString === null) {
      return '---';
    }
    const date = new Date(timeString);
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    return date.toLocaleTimeString('en-US', options);
  }

  updateSanitizedContent() {
    const caption = this.content.post.caption;
    if (!caption) this.sanitizedContent = '-';
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }

  openEditCaptionModal(data){
    const modal = this.modalService.create({
      nzContent: PostEditDialogComponent,
      nzData: data,
      nzFooter: null,
      nzWidth: '50%'
    });
    modal.afterClose.subscribe(response => {
      this.content = this.nzModalData.content
      this.updateSanitizedContent();
    });
  }

  isEditableCaption() {
    return this.content.post.status === 'scheduled' || this.content.post.status === 'rendering'
  }

  checkShowSlide(){
    if((this.content.custom_image_urls?.length > 0 && this.content.media_type === 'image') || (this.content.content_attributes?.custom_image_urls.length > 0 && this.content.content_attributes?.media_type === 'image')){
      this.isShowSlide = true;
    }else{
      this.isShowSlide = false;
    }
  }
}
