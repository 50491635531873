<ng-template #contentpreview>
  <!--  message for post sucess-->
    <div class="steps-content congrats-container" *ngIf="isRescheduled">
      <div class="congrats">
        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
        <h2>Success</h2>
        <p>Your post has been rescheduled successfully.</p>
      </div>
      <nz-divider></nz-divider>
      <div class="ok-btn">
        <button nz-button nzType="primary" (click)="isRescheduled = false">Ok</button>
      </div>
    </div>


    <div *ngIf="!linkedinSuccess && !facebookSuccess && !isRescheduled && content">
      <div nz-row class="form-body">
        <div nz-col nzSpan="24" class="right-spacing ">
          <div *ngIf="config.content.status === 'rendering'">
            <div class="content-media">
              <img class="internal-content" src="assets/styles/Rendering.gif">
            </div>
          </div>
          <div *ngIf="config.content.status !== 'rendering'" class="content-media">
            <div *ngIf="config.content.media_type == 'carousel' && config?.content.fileURLs">
              <app-content-preview-carousel [files]="config?.content.fileURLs"></app-content-preview-carousel>
            </div>
            <div mat-card-image *ngIf="config.content.media_type === 'image'">
              <img *ngIf="config.content.custom_image_urls?.length === 0" [src]="config.content.url" [alt]="config.content.caption" class="internal-content image-content" />
              <app-swiper-images *ngIf="config.content.custom_image_urls?.length > 0" [imageUrls]="config.content.custom_image_urls" ></app-swiper-images>
            </div>
            <video class="internal-content" *ngIf="config.content.media_type === 'video'" autoplay loop [muted]="true"
              controls>
              <source [src]="config.content.url" />
            </video>
          </div>
        </div>
        <!-- <div class="content-preview-divider" nz-col nzSpan="1" nzType="vertical"></div> -->

        <div nz-col nzSpan="24" class="left-spacing content-details-section" *ngIf="showActions">
          <div nz-row *ngIf="isEdit" class="edit-form-container">
            <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
              <span nz-typography>Description/Caption: </span>
              <h5 class="section-description">This is the text that will be included in your post, should you choose to
                post
                this video or flyer.</h5>
              <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
                <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                  <textarea #caption nz-input class="form-control caption-input-area" id="caption"
                    [(ngModel)]="content.caption" name="caption" required maxlength="2200"
                    (ngModelChange)="onInput($event)" (keypress)="handlePreventDefault($event)"></textarea>
                </nz-form-control>
                <span class="remaning">{{content.caption?.length ? content.caption?.length : 0}} / 2200</span>
                <div style="color: red;" *ngIf="content.caption?.length >= 2200">
                  Caption must not exceed 2200 characters.
                </div>
                <div style="color: red;" *ngIf="isHashtagLimit">
                  Hashtag Limit Exceeded: You've reached the maximum allowed number of hashtags. Limit: 30 hashtags
                </div>
                <button nz-dropdown nz-button nzTrigger="click" class="align-right" nzType="text" nzBlock
                  [(nzVisible)]="showEmojiPicker" [nzDropdownMenu]="menu" nzPlacement="bottomRight"
                  (click)="toggleEmojiPicker()">
                  😀
                </button>
                <div class="hashtag">
                  <span># {{ hashtags?.length ? hashtags?.length : 0}} / 30</span>
                </div>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <emoji-mart class="emoji-mart" set="{{set}}" isNative="false" (emojiSelect)="addEmoji($event);" title="Pick your emoji…">
                  </emoji-mart>
                </nz-dropdown-menu>
              </nz-form-item>
              <nz-alert
                nzType="warning"
                nzMessage="Warning"
                nzDescription="Please be aware that including your website link, email, or phone number in the caption of a Google Business post will likely result in that post not going through."
                nzShowIcon
                class="mgb-12"
                nzCloseable="true"
              ></nz-alert>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12" class="left-spacing content-details-section" *ngIf="showActions && !isMultipleUploadImg">
          <div nz-row *ngIf="isEdit" class="edit-form-container">
            <nz-card style="width: 100%; margin-top: 20px; margin-bottom: 20px;" nzTitle="Youtube Required Fields">
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
                <span nz-typography>Title: </span>
                <h5 class="section-description">This is the text that will be included as your title in YouTube videos.</h5>
                <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
                  <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                    <input #caption nz-input class="form-control" id="title" [(ngModel)]="content.title" name="caption" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                <span nz-typography>YouTube video's privacy status: </span><br>
                <nz-select [(ngModel)]="privacyStatus">
                  <nz-option nzValue="private" nzLabel="Private"></nz-option>
                  <nz-option nzValue="public" nzLabel="Public"></nz-option>
                  <nz-option nzValue="unlisted" nzLabel="Unlisted"></nz-option>
                </nz-select>
              </div>
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
                <span nz-typography>Tags: </span>
                <h5 class="section-description">These tags will be used in YouTube videos.</h5>
                <nz-select nzMode="tags" nzPlaceHolder="video tags" [(ngModel)]="videoTags" style="margin-bottom: 20px; width: 100%">
                </nz-select>
              </div>
            </nz-card>
          </div>
        </div>
        <div nz-col nzSpan="12" class="left-spacing content-details-section" *ngIf="showActions && !isMultipleUploadImg">
          <div nz-row *ngIf="isEdit" class="edit-form-container">

            <nz-card style="width: 100%; margin-top: 20px; margin-bottom: 20px;" nzTitle="Tiktok Required Fields" *ngIf="tiktokIntegration && !isMultipleUploadImg">
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
                <span nz-typography>Title: </span>
                <h5 class="section-description">This is the text that will be included as your title in Tiktok videos.</h5>
                <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
                  <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                    <input #caption nz-input class="form-control" id="tiktok_title" [(ngModel)]="tiktokTitle" name="caption" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                <span nz-typography>Tiktok video's privacy status: </span><br>
                <nz-select [(ngModel)]="tiktokPrivacyStatus" nzPlaceHolder="Select Tiktok privacy">
                  <nz-option *ngFor="let option of tiktokIntegration.tiktok_creator_info.data.privacy_level_options" [nzValue]="option" [nzDisabled]="contentDisclourse && option === 'SELF_ONLY'" [nzLabel]="preprocessOption(option)"></nz-option>
                </nz-select>
              </div>
              <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px" >
                <span nz-typography>Allow Users to: </span>
                <label nz-checkbox  [(ngModel)]="comment" [disabled]="tiktokIntegration.tiktok_creator_info.data.comment_disabled">Comment</label>
                <label nz-checkbox [(ngModel)]="duet"  *ngIf="config.content.media_type === 'video'"  [disabled]="tiktokIntegration.tiktok_creator_info.data.duet_disabled">Duet</label>
                <label nz-checkbox [(ngModel)]="stitch"  *ngIf="config.content.media_type === 'video'"  [disabled]="tiktokIntegration.tiktok_creator_info.data.stitch_disabled">Stitch</label>
              </div>
              <div  nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                <span nz-typography>Disclose Video Content: </span>
                <nz-switch [(ngModel)]="contentDisclourse"></nz-switch>
                <p>Your video will be labeled "Promotion Content" this cannot be changed once your video is posted.</p>

                <span *ngIf="contentDisclourse">
                  <label nz-checkbox [(ngModel)]="yourBrand">Your Brand</label>
                <p>Your photo/video will be labeled as 'Promotional content'</p>

                <label nz-checkbox [(ngModel)]="brandedContent">Branded Content</label>
                <p>Your photo/video will be labeled as 'Paid partnership'</p>
                  <ul>
                    <li *ngIf="yourBrand && !brandedContent">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation</a>.</li>
                    <li *ngIf="brandedContent && !yourBrand">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation.</a></li>
                    <li *ngIf="brandedContent && yourBrand">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation.</a></li>
                  </ul>
                </span>

              </div>
            </nz-card>
          </div>
        </div>




            <!-- <div nz-row > -->
            <div *ngIf="isScheduledPost" nz-col [nzLg]="12" [ngClass]="{
            'has-caption':showCaption}" class="schedule-post">
              <span nz-typography>Select a date and time in the future to post your content.</span>
              <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="top-spacing">
                <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_date">Date
                </nz-form-label>
                <!-- [nzDisabled]="!config.content.last_post" -->
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-date-picker class="schedule-picker_input" nzFormat="MM-dd-y" [(ngModel)]="schedule_date"
                    name="scheduled_for_date" [nzDisabledDate]="disabledDate" (ngModelChange)="changeDate($event);">
                  </nz-date-picker>
                  <h5 class="schedule-date-info">
                    <span nz-typography>you are only allowed to schedule within 2 months (60 days).</span>
                  </h5>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24">
                <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_time">Time
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" *ngIf="!isDateLoading">

                  <nz-time-picker class="schedule-picker_input" [nzDisabled]="!schedule_date" [(ngModel)]="schedule_time"
                    name="scheduled_for_time" [nzUse12Hours]="true" nzFormat="h:mm a" [nzDisabledHours]="disabledHours"
                    (ngModelChange)="changeTime($event);">
                  </nz-time-picker>
                </nz-form-control>
                <nz-form-control [nzSm]="24" [nzXs]="24" *ngIf="isDateLoading">
                  <nz-time-picker class="schedule-picker_input" name="scheduled_for_time_temp" [nzUse12Hours]="true"
                    nzFormat="h:mm a">
                  </nz-time-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="socialConfig && isEdit" class="left-spacing" nz-col [nzLg]="isScheduledPost ? 12: 24">
              <app-select-social-platform [submitted]="submitted" class="spacing-bottom" [config]="socialConfig" [uploadMultipleImages] = "isMultipleUploadImg" >
              </app-select-social-platform>
            </div>

          </div>
          <div *ngIf="!isEdit">
            <div class="content-caption">
              <strong>Created at:</strong>
              <span>
                {{config.content.created_at | date : "MM-dd-y h:mm a"}}
              </span>
            </div>
            <div class="content-caption">
              <strong>Last posted at:</strong>
              <span *ngIf="config.content.last_post && config.content.last_post.posted_at">
                {{config.content.last_post.posted_at| date : "MM-dd-y h:mm a"}}
              </span>
              <span
                *ngIf="!config.content.last_post || (config.content.last_post && !config.content.last_post.posted_at)">
                N/A
              </span>
            </div>

            <!--
            <div class="content-caption">
              <strong>Scheduled for:</strong>
              <span
                *ngIf="config.content.last_post && config.content.last_post.id && config.content.last_post.scheduled_for && content.last_post.status !== 'successful'">
                {{config.content.last_post.scheduled_for | date : "MM-dd-y h:mm a"}}
              </span>
              <i *ngIf="config.content.last_post && config.content.last_post.id && config.content.last_post.scheduled_for && config.content?.last_post.status !== 'successful'"
                nzTooltipTitle="cancel schedule" nzTooltipPlacement="leftTop" nz-tooltip (click)="cancelSchedule()"
                class="cancel" nz-icon nzType="close-circle" nzTheme="outline"></i>
              <span
                *ngIf="config.content.last_post && config.content.last_post.id && !config.content.last_post.scheduled_for || content.last_post?.status !== 'scheduled'">
                N/A
              </span>
            </div> -->
            <div class="content-caption">
              <strong>Description/Caption: </strong>{{config.content.caption}}
              <!-- <i (click)="showEdit()" class="edit-icon" nz-icon nzType="edit" nzTheme="outline"></i> -->
            </div>

          </div>

        </div>
  <!--    </div>-->
      <!-- <div nz-col nzSpan="24" class="left-spacing content-details-section"> -->
      <div nz-row nzType="flex" nzJustify="space-between" class="actions" *ngIf="isEdit && showActions">
        <button nz-button nzType="default" class="action-btn" (click)="cancelEdit();">Cancel</button>


        <button nz-button nz-dropdown nzType="primary" class="mgr-8" [disabled]="isHashtagLimit" [nzDropdownMenu]="menu4"
          *ngIf="!isScheduledPost">
          Post
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu *ngIf="!isHashtagLimit">
            <li nz-menu-item (click)="render()">
              <i nz-icon nzType="sketch"></i>
              Render
            </li>
            <li (click)="isScheduledPost = true" nz-menu-item [nzDisabled]="customer.render_only">
              <i nz-icon nzType="field-time" nzTheme="outline"></i>
              Schedule
            </li>
            <li nz-menu-item (click)="post()" [nzDisabled]="customer.render_only">
              <i nz-icon nzType="share-alt" [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
                class="down-icon" (click)="post()"></i>
              Post Now
            </li>
          </ul>
        </nz-dropdown-menu>

        <button nz-button nzType="primary" class="action-btn-primary" (click)="reSchedule()"
          *ngIf="config.content.last_post && config.content.last_post.id && config.content.last_post.scheduled_for && config.content.last_post.status !== 'successful'; else postbtn"
          [disabled]="!(content.status === 'ready' || content.status === 'scheduled') || !(socialConfig?.facebook || socialConfig?.linkedin || socialConfig?.instagram) || !isDateChanged || !schedule_date || !schedule_time || customer.render_only">Reschedule</button>
        <ng-template #postbtn>

          <button nz-button nzType="primary" class="action-btn-primary" (click)="post()" *ngIf="isScheduledPost"
            [disabled]="!((socialConfig?.facebook || socialConfig?.linkedin || socialConfig?.instagram || socialConfig?.youtube) && schedule_time && schedule_date) || customer.render_only">Post</button>
        </ng-template>

      </div>
      <div *ngIf="!isEdit && showActions && config?.content" nz-row nzType="flex" nzJustify="space-between" class="actions">
        <button nz-button nzType="default" nzDanger (click)="contentDeleted(config.content)">
          <i nz-icon nzType="delete"></i>Delete</button>
        <button nz-button nzType="default" class="action-btn" [disabled]="config.content.status !== 'ready'"
          (click)="download();">
          <i nz-icon nzType="download" [ngStyle]="{'color': config.content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
            class="down-icon"></i>
          Download</button>
        <button nz-button nzType="primary" class="action-btn-primary" (click)="showReschedule()"
          *ngIf="config.content.last_post && config.content.last_post.id && config.content.last_post.scheduled_for && config.content.last_post.status === 'scheduled'; else postbtn1"
          [disabled]="!(content.status === 'ready' || content.status === 'scheduled') || customer.render_only">Reschedule</button>
        <ng-template #postbtn1>
          <button nz-button nzType="primary" class="action-btn-primary" (click)="showEdit()"
            [disabled]="!(content.status === 'ready' || content.status === 'scheduled') || customer.render_only">Post</button>
        </ng-template>
      </div>
      <!-- </div> -->

  <!--  </div>-->

    <div class="steps-content congrats-container" *ngIf="linkedinSuccess || facebookSuccess">
      <div class="congrats">
        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
        <h2>Success</h2>
        <p>Your post has been made successfully.</p>
      </div>
      <nz-divider></nz-divider>
      <div class="ok-btn">
        <button nz-button nzType="default" (click)="removeDialog()">
          ok
        </button>
      </div>
    </div>
  </ng-template>
  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <ng-container [ngTemplateOutlet]="contentpreview"></ng-container>
  </nz-spin>
  <ng-template #notloading>
    <ng-container [ngTemplateOutlet]="contentpreview"></ng-container>
  </ng-template>

