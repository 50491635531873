import moment from 'moment';
import { Image } from '../image/image';
import { Resource } from '../resource/resource';
import { Product } from '../product/product';
import { WebsiteAttributes } from 'src/app/shared/interfaces/website-attributes';

export function utcDate(time_stamp: string): Date {
  const local_date = new Date(time_stamp);
  const utc_date = Date.UTC(
    local_date.getUTCFullYear(),
    local_date.getUTCMonth(),
    local_date.getUTCDay()
  );
  return new Date(utc_date);
}
export class Listing extends Resource {
  /**
   * id
   */
  id?: number;

  /**
   * created_at
   */
  created_at?: moment.Moment;

  /**
   * status
   */
  listing_status: string;
  mls_status: string;
  mls_listing_type: string;

  /**
   * locale
   */
  locale: string;

  /**
   * address
   */
  address: string;

  /**
   * city
   */
  city: string;

  /**
   * state
   */
  state: string;

  /**
   * zip
   */
  zip: string;
  /**
   * zip
   */
  street: string;

  latitude: number;
  longitude: number;

  /**
   * price
   */
  price: string;

  /**
   * beds
   */
  beds: string;

  /**
   * beds
   */
  baths: string;

  /**
   * sq_ft
   */
  sq_ft: any;

  /**
   * lot_size
   */
  lot_size: string;

  lot_size_type: string;

  /**
   * year_build
   */
  year_build: string;

  /**
   * type
   */
  listing_type: string;

  /**
   * url
   */
  url: string;

  /**
   * description
   */
  description: string;

  /**
   * images
   */
  images: Image[];

  /**
   * congrats
   */
  congrats: string;

  /**
   * family_name
   */
  family_name: string;
  /**
   * listing products
   */
  products: Product[];
  oh_from_date: string;
  oh_start_time: string;
  oh_end_time: string;

  get isCommercial(): boolean {
    return ['Office', 'Retail', 'Industrial', 'Multi Family'].includes(this.listing_type);
  }

  grm: string;
  docks: string;
  units: string;
  cap_rate: string;
  unit_mix: string;
  transaction_type: string;
  parking: string;
  tenancy: string;
  occupancy: string;
  price_per_unit: string;
  price_per_sqft: string;
  pro_forma_cap_rate: string;
  building_class: string;
  lease_type: string;
  lease_rate: string;
  zoning: string;
  /**
   * family_photo
   */
  family_photo: Image;
  remote_family_photo_url: string;
  remove_family_photo?: boolean;
  /**
   * buy_sell
   */
  buy_sell: string;

  property_attributes: any;
  /**
   * buy_sell
   */
  images_attributes?: Image[];
  extra_images?: Image[];
  websites_attributes: WebsiteAttributes[];
  // products: { [ key: string ]: string };
  get featured_images(): Image[] {
    const order_featured = this.images_attributes?.filter(i => i.order != null).sort(i => i.order);
    const norder_featured = this.images_attributes?.filter(i => i.order == null);
    const im_featured = [...order_featured, ...norder_featured].sort(i => i.order);
    im_featured.length = 5;
    return im_featured;
  }

  constructor(vals: any = { images_attributes: [] }) {
    if (typeof vals.price === 'string') {
      vals.price = +vals.price?.replace(/[^0-9.]/g, '');
    }

    super(vals);
    // this.images.length = 5;
    if (!this.lot_size_type) {
      this.lot_size_type = 'acres';
    }
  }
}
