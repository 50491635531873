import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import Swiper, { Navigation, SwiperOptions, Pagination } from 'swiper';

Swiper.use([Pagination]);
@Component({
  selector: "app-swiper-images",
  templateUrl: "slide-images.component.html",
  styleUrls: ["slide-images.component.scss"]
})
export class SlideImagesComponent implements AfterViewInit {
  @ViewChild('swiperContainer', { static: false }) swiperContainer: any;
  swiper: Swiper | undefined;
  @Input() imageUrls: string[] = [];
  @Input() showRemoveButton = false;
  @Output() imageRemoved = new EventEmitter<string>();
  urls: string[] = [];
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: { el: ".swiper-pagination", clickable: true },
    navigation: {
      nextEl: ".swiper-button-next-img",
      prevEl: ".swiper-button-prev-img"
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 30
        },
        1024: {
            slidesPerView: 1,
            spaceBetween: 40
        }
    }
  };

  normalizeImageUrls(imageUrls: (string | { url: string; type?: string })[]): string[] {
    return imageUrls.map(item => (typeof item === 'string' ? item : item.url));
  }

  
  ngOnInit() {
    this.urls = this.normalizeImageUrls(this.imageUrls);
  }

  ngOnChanges() {
    this.urls = this.normalizeImageUrls(this.imageUrls);
    
    setTimeout(() => {
      if (this.swiper) {
        this.swiper.updateSlides();
        this.swiper.update();
      }
    });
  }

  ngAfterViewInit() {
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      ...this.config,
      on: {
      
      }
    });

    const nextButton = this.swiperContainer.nativeElement.querySelector('.swiper-button-next-img');
    const prevButton = this.swiperContainer.nativeElement.querySelector('.swiper-button-prev-img');

    if (nextButton && prevButton) {
      nextButton.addEventListener('click', (e) => {
        console.log('Next button clicked');
        this.swiper?.slideNext();
        e.stopPropagation();
      });

      prevButton.addEventListener('click', (e) => {
        console.log('Prev button clicked');
        this.swiper?.slidePrev();
        e.stopPropagation();
      });
    }
  }

  removeImage(imageUrl: string, event: Event) {
    event.stopPropagation();
    this.imageRemoved.emit(imageUrl);
  }
}