import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, inject } from '@angular/core';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import Swiper, { Autoplay } from 'swiper';

import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentsService } from '../../services/contents/contents.service';

import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {PostEditDialogComponent} from '../post-edit-dialog/post-edit-dialog.component';
import { EventPreviewPostComponent } from 'src/app/components/content-calender/event-preview/event-preview-post/event-preview-post.component';

Swiper.use([Autoplay]);
@Component({
  selector: 'app-content-tab-preview-dialog',
  templateUrl: './content-tab-preview-dialog.component.html',
  styleUrls: ['./content-tab-preview-dialog.component.scss']
})
export class ContentTabPreviewDialogComponent implements OnInit {
  @ViewChild('caption', { static: false }) captionInput: ElementRef;
  socialConfig;

  @Input() config: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  isRescheduled = false;
  content;
  posts;
  customer: Customer;
  sanitizedContent: SafeHtml;
  isShowSlide = false;

  constructor(
    private contentsService: ContentsService,
    private modal: NzModalRef,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer

  ) {
  }
  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config;
    }
    if (!this.config){
      this.loadContentConfig();
    } else{
      this.setContentConfigForInit();
    }

    this.getPosts();

    this.checkShowSlide();
    
  }

  setContentConfigForInit(){
    this.setSocialContentConfig();
    this.content = JSON.parse(JSON.stringify(this.config.content));
    this.updateSanitizedContent();
  }

  loadContentConfig(){
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.contentsService.show(params.id).subscribe(response => {
          this.config = { content: response.data, isEdit: true };
          this.setContentConfigForInit();
        }, error => {
          this.router.navigate(['NotFound']);
        });
      }
    });
  }

  setSocialContentConfig() {
    const content = this.config?.content;
    const hideGoogle = content?.media_type === 'video';
    this.socialConfig = { content: content, facebook: false, linkedin: false, google: false, youtube: false, hideGoogle };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }

  async download() {
    const content = this.config.content;
    let url = content.url;
    if (!url && content?.fileURLs && content.fileURLs.length) {
      await this.downloader.createZip(content?.fileURLs, content.display_name);
    } else if (url && !url.includes('https')) {
      url = url?.replace('http', 'https');
    }
    this.downloader.save(url, content.filename);
  }

  showEdit() {
    this.modalService.closeAll();
    this.router.navigate(['/content', this.content.id, 'post']);
  }


  handleDeleteContent(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?\nThis action will only remove it from our platform. The posts already shared on social media will remain visible on those platforms unless you delete them there separately.\nThis cannot be undone.`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.contentsService.destroy(content)
          .subscribe(res => {
            this.message.create('success', `Content has been successfully deleted.`);
            this.modal.close({ action: 'deleted' });
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }

  updateSanitizedContent() {
    const caption = this.config?.content?.caption;

    if (!caption) this.sanitizedContent = null;
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }

  getPosts(){
    if (this.content && this.content.id){
      this.contentsService.posts(this.content).subscribe(resp => {
        this.posts = resp;
      });
    }
  }

  isVideo(url) {
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'];
    const extension = url.split('.').pop().toLowerCase();

    if (videoExtensions.includes(extension)) return true;
    else return false;
  }

  openEventPreviewPostModal(social){
    this.content.post = this.posts.filter(p => p.id === social)[0];
    const modal = this.modalService.create<EventPreviewPostComponent, IModalData>({
      nzContent: EventPreviewPostComponent,
      nzData: {
        content: this.content,
      },
      nzFooter: null,
      nzWrapClassName: 'event-preview-modal'
    })
  }

  checkShowSlide(){
    if(this.content.custom_image_urls.length > 0 && this.content.media_type === 'image'){
      this.isShowSlide = true;
    }else{
      this.isShowSlide = false;
    }
  }
}