import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ListingFormComponent } from './listing-form/listing-form.component';
import { ImagesReorderComponent } from './images-reorder/images-reorder.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { CreateTestimonialComponent } from '../components/testimonials/create/create.component';
import { YoutubeOnboardingDialogComponent } from '../shared/youtube-onboarding-dialog/youtube-onboarding-dialog.component';
import { UsericonComponent } from '../shared/usericon/usericon.component';
import { TiktokOnboardingDialogComponent } from '../shared/tiktok-onboarding-dialog/tiktok-onboarding-dialog.component';
import { SocialPostDialogComponent } from '../shared/social-post-dialog/social-post-dialog.component';
import { SocialLinksDialogComponent } from './social-links-dialog/social-links-dialog.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { RoiReportsComponent } from '../shared/roi-reports/roi-reports.component';
import { PdfViewerDialogComponent } from '../shared/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { NpsScoreDialogComponent } from '../shared/nps-score-dialog/nps-score-dialog.component';
import { CancelledCustomerDialogComponent } from '../shared/cancelled-customer-dialog/cancelled-customer-dialog.component';
import { NotificationTabComponent } from '../shared/notification-tab/notification-tab.component';
import { ListingImageGalleryComponent } from '../shared/listing-image-gallery/listing-image-gallery.component';
import { LinkedinOnboardingDialogComponent } from '../shared/linkedin-onboarding-dialog/linkedin-onboarding-dialog.component';
import { InsightsAnalyticsComponent } from '../shared/insights-analytics/insights-analytics.component';
import { GoogleOnboardingDialogComponent } from '../shared/google-onboarding-dialog/google-onboarding-dialog.component';
import { GenericDialogComponent } from '../shared/generic-dialog/generic-dialog.component';
import { FacebookOnboardingDialogComponent } from '../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { ContentPreviewDialogComponent } from '../shared/content-preview-dialog/content-preview-dialog.component';
import { ContentPreviewCarouselComponent } from '../shared/content-preview-carousel/content-preview-carousel.component';
import { ContentApprovalModalComponent } from '../shared/content-approval-modal/content-approval-modal.component';
import { ContactsUploaderComponent } from '../shared/contacts-uploader/contacts-uploader.component';
import { BreadCrumComponent } from '../shared/bread-crum/bread-crum.component';
import { CreateContactListDialogComponent } from '../shared/create-contact-list-dialog/create-contact-list-dialog.component'
import { MarketReportFormComponent } from './market-report-form/market-report-form.component';
import { ListingsSelectorComponent } from '../shared/listings-selector/listings-selector.component';
import { SelectAudienceComponent } from '../shared/select-audience/select-audience.component';
import { ContentPreviewItemComponent } from '../components/content/content-preview-item/content-preview-item.component';
import { ContentRequestComponent } from '../components/content-request/content-request.component';
import { MarketReportDataSelectorComponent } from '../shared/market-report-data-selector/market-report-data-selector.component';
import { SelectSocialPlatformComponent } from '../shared/select-social-platform/select-social-platform.component';
import { CustomerProductStylesComponent } from '../shared/customer-product-styles/customer-product-styles.component';
import { DashboardNotificationComponent } from '../components/dashboard-notification/dashboard-notification.component';
import { CreateWebsiteComponent } from '../components/websites/create/create.component';
import { SelectTestimonyContentComponent } from '../components/testimonies/select-content/select-content.component';
import { EditTestimonyComponent } from '../components/testimonies/edit/edit.component';
import { TermsComponent } from '../components/terms/terms.component';
import { TeamMembersFormComponent } from '../components/team-members-form/team-members-form.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { SelectCreateContentComponent } from '../components/select-create-content/select-create-content.component';
import { ScheduledContentCalendarComponent } from '../components/scheduled-content-calendar/scheduled-content-calendar.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { RegistrationFormComponent } from '../components/registration-form/registration-form.component';
import { RegisterComponent } from '../components/register/register.component';
import { RecordVideoComponent } from '../components/record-video/record-video.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { EmailSettingsComponent } from '../components/onboarding-email/email-settings/email-settings.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { MarketVideoComponent } from '../components/market-video/market-video.component';
import { MarketReportsViewComponent } from '../components/market-reports/view/view.component';
import { SelectMarketContentComponent } from '../components/market-reports/select-content/select-content.component';
import { MarketReportsComponent } from '../components/market-reports/market-reports.component';
import { MarketReportFormModalComponent } from '../components/market-reports/market-report-form-modal/market-report-form-modal.component';
import { EditMarketReportComponent } from '../components/market-reports/edit/edit.component';
import { MagicLoginComponent } from '../components/magic-login/magic-login.component';
import { MagicLoginLinkComponent } from '../components/magic-login-link/magic-login-link.component';
import { ReactivateTrialComponent } from '../components/reactivate-trial/reactivate-trial.component';
import { LoginComponent } from '../components/login/login.component';
import { LandingPageComponent } from '../components/landing-page/landing-page.component';
import { InfographicsComponent } from '../components/infographics/infographics.component';
import { HomeComponent } from '../components/home/home.component';
import { FacebookStepsComponent } from '../components/facebook-steps/facebook-steps.component';
import { EmailSignatureComponent } from '../components/email-signature/email-signature.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { CreateContentComponent } from '../components/create-content/create-content.component';
import { CrmComponent } from '../components/crm/crm.component';
import { ConversationsComponent } from '../components/conversations/conversations.component';
import { ContentComponent } from '../components/content/content.component';
import { ContactsListComponent } from '../components/contacts-list/contacts-list.component';
import { ConfirmationComponent } from '../components/confirmation/confirmation.component';
import { CreateCampaignComponent } from '../components/campaigns/create/create.component';
import { ContactsComponent } from '../components/contacts/contacts.component';
import { TemplatePreviewComponent } from '../components/campaign-flow/template-preview/template-preview.component';
import { EmailTemplateDetailPreviewComponent } from '../components/campaign-flow/email-template-detail-preview/email-template-detail-preview.component';
import { CampaignFlowComponent } from '../components/campaign-flow/campaign-flow.component';
import { CampaignDetailsComponent } from '../components/campaign-flow/campaign-details/campaign-details.component';
import { AudiencesComponent } from '../components/audiences/audiences.component';
import { CreateAnnouncementComponent } from '../components/announcement/create/create.component';
import { AccountMembersFormComponent } from '../components/account-members-form/account-members-form.component';
import { AppComponent } from '../app.component';


import { CommercialPropertyTypeSelector } from './commercial-property-type-selector/commercial-property-type-selector.component';
import { SingleDataSnapShotFormComponent } from './single-data-snapshot-form/single-data-snapshot-form.component';
import { SelectListingContentComponent } from '../components/listings/select-content/select-content.component';
import { ContentTableComponent } from '../shared/content-table/content-table.component';
import { ContactsSelectorComponent } from './contacts-selector/contacts-selector.component';
import { CardButtonModule } from './../modules/card-button/card-button.module';
import { DemoNgZorroAntdModule } from '../ng-zorro-antd.module';
import { SelectResourceComponent } from '../components/select-resource/select-resource.component';
import { AcceptAUPDialogComponent } from './accept-aup-dialog/accept-aup-dialog.component';
import { WelcomePackageComponent } from '../components/welcome-package/welcome-package.component';
import { SenderFormComponent } from '../components/onboarding-email/sender-form/sender-form.component';
import { OnboardingEmailComponent } from '../components/onboarding-email/onboarding-email.component';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AudiencesService } from '../services/audiences/audiences.service';
import { AnnouncementService } from '../services/announcement/announcement.service';
import { LeadsService } from '../services/leads/leads.service';
import { TipsService } from '../services/tips/tips.service';
import { SmsTemplatesService } from '../services/sms-templates/sms-templates.service';

import { ContactsService } from '../services/contacts/contacts.service';
import { BrokerageService } from '../services/brokerage/brokerage.service';
import { RegionService } from '../services/region/region.service';


import { ListingsService } from '../services/listings/listings.service';
import { CustomerProductStylesService } from '../services/customer-product-styles/customer-product-styles.service';
// import { AuthenticationService } from '../services/authentication/authentication.service';
import { ContentsService } from '../services/contents/contents.service';
import { PostsService } from '../services/posts/posts.service';
import { CustomersService } from '../services/customers/customers.service';
import { MarketReportsService } from '../services/market-reports/market-reports.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductsService } from '../services/products/product-api.service';
import { RouterModule } from '@angular/router';
import { ContentStatusPipe } from '../pipes/content/content-status.pipe';
import { ContentCreatedPipe } from '../pipes/content/content-created.pipe';
import { ContentTypePipe } from '../pipes/content/content-type.pipe';
import { ContentParentPipe } from '../pipes/content/content-parent.pipe';
import { NumberInputFormatterPipe } from '../pipes/formatting/number-input-formatter.pipe';
import { InfographicsService } from '../services/infographics/infographics.service';
import { BrandedInfographicsService } from '../services/branded-infogrpahics/branded-infographics.service';
import { IntegrationsService } from '../services/integrations/integrations.service';
import { MlsService } from '../services/mls/mls.service';
import { InsightsService } from '../services/insights/insights.service';
import { OnboardingService } from '../services/onboarding/onboarding.service';
import { WelcomePackageService } from '../services/welcome-package/welcome-package.service';
import { SafePipe } from '../pipes/sanitize/sanitize.pipe';
import { TruncateWordsPipe } from '../pipes/content/truncate.pipe';
import { PropmixService } from '../services/propmix/propmix.service';
import { BillingService } from '../services/billing/billing.service';
import { SubscriptionService } from '../services/subscriptions/subscriptions.service';
import { ActionCableService } from 'angular2-actioncable';
import { SettingsService } from '../services/settings/settings.service';
import { PaginationService } from '../services/pagination/pagination.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { WebsiteService } from '../services/websites/website.service';
import { TemplatesService } from '../services/templates/templates.service';
import { CaptionsService } from '../services/captions/captions.service';
import { AiTemplateService } from '../services/ai-template/ai-template.service';
import { RssFeedService } from '../services/rss-feed/rss-feed.service';
import { CustomContentService } from '../services/custom-contents/custom-content.service'
import { CampaignService } from '../services/campaigns/campaign.service';

import { ImagesService } from '../services/images/images.service';
import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageHandlerComponent } from './image-handler/image-handler.component';
import { ContentFormComponent } from '../shared/content-form/content-form.component';
import { SelectImageCardComponent } from '../shared/select-image-card/select-image-card.component';
import { CustomerFormComponent } from '../shared/customer-form/customer-form.component';
import { SelectContentComponent } from '../shared/select-content/select-content.component';
import { AudienceSelectorComponent } from './audience-selector/audience-selector.component';
import { MlsConnectionComponent } from '../shared/mls-connection/mls-connection.component';
import { CaptionsComponent } from '../shared/captions/captions.component';

import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import {ImageGalleryComponent} from './image-gallery/image-gallery.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { ContentCalenderComponent } from '../components/content-calender/content-calender.component';
import { CustomContentDialogComponent } from '../components/custom-content-dialog/custom-content-dialog.component';
import { PostEditDialogComponent } from '../shared/post-edit-dialog/post-edit-dialog.component';
import { RssFeedDialogComponent } from '../components/rss-feed-dialog/rss-feed-dialog.component';
import { TipsComponent } from '../components/tips/tips.component';
import { NewsComponent } from '../components/news/news/news.component';
import { CelebLuxuryComponent } from '../components/celeb-luxury/celeb-luxury.component'
import { TemplatesComponent } from '../components/templates/templates.component';
import { CampaignTemplatesComponent } from '../components/campaign-templates/campaign-templates.component';
import { CampaignsComponent } from '../components/campaigns/campaigns.component';
import { ListingDialogComponent } from './listing-dialog/listing-dialog.component';
import { TestimonialsDialogComponent } from './testimonials-dialog/testimonials-dialog.component';
import { AutomatedCampaignsDialogComponent } from './automated-campaigns-dialog/automated-campaigns-dialog.component';
import { RssAlreadyPostedComponent } from './rss-already-posted/rss-already-posted.component';
import { RegisterGhlLocationDialogComponent } from './register-ghl-location-dialog/register-ghl-location-dialog.component';
import { ContentPostComponent } from './content-post/content-post.component';
import {ConversationsService} from '../services/conversations/conversations.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {LeadsModule} from '../modules/leads/leads.module';
import {MarketplaceComponent} from '../components/marketplace/marketplace.component';
import {HumanizePipe} from '../pipes/content/humanize.pipe';
import {StripeAddressComponent, StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent} from 'ngx-stripe';
import { SlideImagesComponent } from '../components/slide-images/slide-images.component';
import { ContentTabPreviewDialogComponent } from './content-tab-preview-dialog/content-tab-preview-dialog.component';

// FullCalendarModule.registerPlugins([
//   // register FullCalendar plugins
//   dayGridPlugin,
//   interactionPlugin,
// ]);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    DemoNgZorroAntdModule,
    ScrollingModule,
    InfiniteScrollModule,
    ImageCropperModule,
    DragDropModule,
    CardButtonModule,
    PickerModule,
    FullCalendarModule,
    PdfViewerModule,
    LeadsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    StripeCardComponent,
    StripeAddressComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    ContentCreatedPipe,
    ContentStatusPipe,
    ContentTypePipe,
    ContentParentPipe,
    NumberInputFormatterPipe,
    SafePipe,
    TruncateWordsPipe,
    NumberInputFormatterPipe,
    CheckoutComponent,
    ListingFormComponent,
    ImagesReorderComponent,
    ImageHandlerComponent,
    ContentCardComponent,
    MarketReportFormComponent,
    ListingsSelectorComponent,
    SelectAudienceComponent,
    SingleDataSnapShotFormComponent,
    CommercialPropertyTypeSelector,
    ImageCropperDialogComponent,
    ContentTableComponent,
    SelectImageCardComponent,
    SelectListingContentComponent,
    SelectResourceComponent,
    SelectImageCardComponent,
    ContentFormComponent,
    CustomerFormComponent,
    SelectContentComponent,
    ContactsSelectorComponent,
    AudienceSelectorComponent,
    MlsConnectionComponent,
    ContentPreviewItemComponent,
    ImageGalleryComponent,
    ContentRequestComponent,
    MarketReportDataSelectorComponent,
    CreateTestimonialComponent,
    SelectSocialPlatformComponent,
    CustomerProductStylesComponent,
    CaptionsComponent,
    ContentCalenderComponent,
    CustomContentDialogComponent,
    PostEditDialogComponent,
    TipsComponent,
    NewsComponent,
    CelebLuxuryComponent,
    TemplatesComponent,
    RssFeedDialogComponent,
    YoutubeOnboardingDialogComponent,
    CampaignsComponent,
    CampaignTemplatesComponent,
    ListingDialogComponent,
    TestimonialsDialogComponent,
    AutomatedCampaignsDialogComponent,
    RssAlreadyPostedComponent,
    RegisterGhlLocationDialogComponent,
    UsericonComponent,
    TiktokOnboardingDialogComponent,
    SocialPostDialogComponent,
    SocialLinksDialogComponent,
    SidebarComponent,
    RoiReportsComponent,
    PdfViewerDialogComponent,
    NpsScoreDialogComponent,
    CancelledCustomerDialogComponent,
    NotificationTabComponent,
    ListingImageGalleryComponent,
    LinkedinOnboardingDialogComponent,
    InsightsAnalyticsComponent,
    GoogleOnboardingDialogComponent,
    GenericDialogComponent,
    FacebookOnboardingDialogComponent,
    ContentPreviewDialogComponent,
    ContentPreviewCarouselComponent,
    ContentApprovalModalComponent,
    ContactsUploaderComponent,
    BreadCrumComponent,
    CreateContactListDialogComponent,
    DashboardNotificationComponent,
    AcceptAUPDialogComponent,
    WelcomePackageComponent,
    CreateWebsiteComponent,
    SelectTestimonyContentComponent,
    EditTestimonyComponent,
    TermsComponent,
    TeamMembersFormComponent,
    SettingsComponent,
    SelectCreateContentComponent,
    ScheduledContentCalendarComponent,
    ResetPasswordComponent,
    RegistrationFormComponent,
    RegisterComponent,
    RecordVideoComponent,
    ProfileComponent,
    SenderFormComponent,
    OnboardingEmailComponent,
    EmailSettingsComponent,
    NotFoundComponent,
    MarketVideoComponent,
    MarketReportsViewComponent,
    SelectMarketContentComponent,
    MarketReportsComponent,
    MarketReportFormModalComponent,
    EditMarketReportComponent,
    MagicLoginComponent,
    MagicLoginLinkComponent,
    ReactivateTrialComponent,
    LoginComponent,
    LandingPageComponent,
    InfographicsComponent,
    HomeComponent,
    FacebookStepsComponent,
    EmailSignatureComponent,
    DashboardComponent,
    CreateContentComponent,
    CrmComponent,
    MarketplaceComponent,
    ConversationsComponent,
    ContentComponent,
    ContactsListComponent,
    ConfirmationComponent,
    CreateCampaignComponent,
    ContactsComponent,
    TemplatePreviewComponent,
    EmailTemplateDetailPreviewComponent,
    CampaignFlowComponent,
    CampaignDetailsComponent,
    AudiencesComponent,
    CreateAnnouncementComponent,
    AccountMembersFormComponent,
    AppComponent,
    ContentPostComponent,
    HumanizePipe,
    SlideImagesComponent,
    ContentTabPreviewDialogComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    ContentCreatedPipe,
    ContentStatusPipe,
    ContentTypePipe,
    ContentParentPipe,
    NumberInputFormatterPipe,
    SafePipe,
    TruncateWordsPipe,
    DragDropModule,
    ScrollingModule,
    InfiniteScrollModule,
    ListingFormComponent,
    ImagesReorderComponent,
    ImageHandlerComponent,
    ContentCardComponent,
    MarketReportFormComponent,
    ListingsSelectorComponent,
    ImageCropperModule,
    ImageCropperDialogComponent,
    SingleDataSnapShotFormComponent,
    CommercialPropertyTypeSelector,
    ContentTableComponent,
    SelectImageCardComponent,
    SelectListingContentComponent,
    SelectResourceComponent,
    SelectImageCardComponent,
    ContentFormComponent,
    CustomerFormComponent,
    MlsConnectionComponent,
    SelectContentComponent,
    CardButtonModule,
    ContactsSelectorComponent,
    AudienceSelectorComponent,
    SelectAudienceComponent,
    PickerModule,
    ContentPreviewItemComponent,
    ImageGalleryComponent,
    ContentRequestComponent,
    MarketReportDataSelectorComponent,
    CreateTestimonialComponent,
    SelectSocialPlatformComponent,
    CustomerProductStylesComponent,
    CaptionsComponent,
    FullCalendarModule,
    ContentCalenderComponent,
    CustomContentDialogComponent,
    PostEditDialogComponent,
    TipsComponent,
    NewsComponent,
    TemplatesComponent,
    RssFeedDialogComponent,
    YoutubeOnboardingDialogComponent,
    CampaignsComponent,
    CampaignTemplatesComponent,
    ListingDialogComponent,
    TestimonialsDialogComponent,
    AutomatedCampaignsDialogComponent,
    RssAlreadyPostedComponent,
    RegisterGhlLocationDialogComponent,
    UsericonComponent,
    TiktokOnboardingDialogComponent,
    SocialPostDialogComponent,
    SocialLinksDialogComponent,
    SidebarComponent,
    RoiReportsComponent,
    PdfViewerDialogComponent,
    NpsScoreDialogComponent,
    CancelledCustomerDialogComponent,
    NotificationTabComponent,
    ListingImageGalleryComponent,
    LinkedinOnboardingDialogComponent,
    InsightsAnalyticsComponent,
    GoogleOnboardingDialogComponent,
    GenericDialogComponent,
    FacebookOnboardingDialogComponent,
    ContentPreviewDialogComponent,
    ContentPreviewCarouselComponent,
    ContentApprovalModalComponent,
    ContactsUploaderComponent,
    BreadCrumComponent,
    CreateContactListDialogComponent,
    DashboardNotificationComponent,
    AcceptAUPDialogComponent,
    WelcomePackageComponent,
    CreateWebsiteComponent,
    SelectTestimonyContentComponent,
    EditTestimonyComponent,
    TermsComponent,
    TeamMembersFormComponent,
    SettingsComponent,
    SelectCreateContentComponent,
    ScheduledContentCalendarComponent,
    ResetPasswordComponent,
    RegistrationFormComponent,
    RegisterComponent,
    RecordVideoComponent,
    ProfileComponent,
    SenderFormComponent,
    OnboardingEmailComponent,
    EmailSettingsComponent,
    NotFoundComponent,
    MarketVideoComponent,
    MarketReportsViewComponent,
    SelectMarketContentComponent,
    MarketReportsComponent,
    MarketReportFormModalComponent,
    EditMarketReportComponent,
    MagicLoginComponent,
    MagicLoginLinkComponent,
    ReactivateTrialComponent,
    LoginComponent,
    LandingPageComponent,
    InfographicsComponent,
    HomeComponent,
    FacebookStepsComponent,
    EmailSignatureComponent,
    DashboardComponent,
    CreateContentComponent,
    CrmComponent,
    MarketplaceComponent,
    ConversationsComponent,
    ContentComponent,
    ContactsListComponent,
    ConfirmationComponent,
    CreateCampaignComponent,
    ContactsComponent,
    TemplatePreviewComponent,
    EmailTemplateDetailPreviewComponent,
    CampaignFlowComponent,
    CampaignDetailsComponent,
    AudiencesComponent,
    CreateAnnouncementComponent,
    AccountMembersFormComponent,
    AppComponent,
    ContentPostComponent,
    SlideImagesComponent,
    ContentTabPreviewDialogComponent
  ],
  providers: [
    // AuthenticationService,
    CustomerProductStylesService,
    ListingsService,
    ContentsService,
    PostsService,
    CustomersService,
    MarketReportsService,
    ProductsService,
    InfographicsService,
    BrandedInfographicsService,
    IntegrationsService,
    MlsService,
    PropmixService,
    BillingService,
    SubscriptionService,
    InsightsService,
    OnboardingService,
    WelcomePackageService,
    ActionCableService,
    SettingsService,
    PaginationService,
    WebsiteService,
    TemplatesService,
    ImagesService,
    LeadsService,
    TipsService,
    SmsTemplatesService,
    AudiencesService,
    AnnouncementService,
    ContactsService,
    BrokerageService,
    RegionService,
    CaptionsService,
    AiTemplateService,
    RssFeedService,
    CustomContentService,
    CampaignService,
    ConversationsService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: 'FULL_CALENDAR_OPTIONS',
      useValue: {
        plugins: [ dayGridPlugin, interactionPlugin ]
      }
    }
  ],
})

/**
 * Shares common components, directives, and pipes that
 * may not be as application specific.
 *
 * @see https://angular.io/guide/ngmodule#shared-modules
 */
export class SharedModule {}
