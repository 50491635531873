import { Component, OnInit, Input, inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { ContentsService } from '../../../../services/contents/contents.service';
import { GenericDialogComponent } from '../../../../shared/generic-dialog/generic-dialog.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {Content} from '../../../../vos/content/content';
import { EventPreviewPostComponent } from '../event-preview-post/event-preview-post.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'content-event-preview-content',
  templateUrl: './event-preview-content.component.html',
  styleUrls: ['./event-preview-content.component.scss']
})
export class EventPreviewContentComponent implements OnInit {
  @Input()
  calendarData;
  @Input() content?: any;
  posts;

  showDelete = false;
  isNoSupportDeletePostOnSocial = ['instagram', 'tiktok'];
  sanitizedContent: SafeHtml;
  isShowSlide = false;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private contentsService: ContentsService,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnInit() {
    this.content = this.nzModalData.content
    this.getPosts();
    this.updateSanitizedContent();
    this.checkShowSlide();
  }

  handleDeleteContent(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?\nThis action will only remove it from our platform. The posts already shared on social media will remain visible on those platforms unless you delete them there separately.\nThis cannot be undone.`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        const content_delete = content.content_attributes;
        this.contentsService.destroy(content_delete)
          .subscribe(res => {
            this.message.create('success', `Content has been successfully deleted.`);
            this.#modal.close({ action: 'deleted' });
            setTimeout(() => {
              this.modalService.closeAll();
            }, 100);
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }

  close(){
    this.modalService.closeAll();
  }

  getPosts(){
    const content = new Content();
    content.id = this.content.content_id;
    this.contentsService.posts(content).subscribe(resp => {
      this.posts = resp;
    });
  }

  openEventPreviewPostModal(social){
    this.content.post = this.posts.filter(p => p.id === social)[0];
    const modal = this.modalService.create<EventPreviewPostComponent, IModalData>({
      nzContent: EventPreviewPostComponent,
      nzData: {
        content: this.content,
      },
      nzFooter: null,
      nzWrapClassName: 'event-preview-modal'
    })
  }

  updateSanitizedContent() {
    const caption =  this.content.content_attributes?.caption

    if (!caption) this.sanitizedContent = '-';
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }

  checkShowSlide(){
    if(this.content.content_attributes.custom_image_urls.length > 0 && this.content.content_attributes.media_type === 'image'){
      this.isShowSlide = true;
    }else{
      this.isShowSlide = false;
    }
  }
}