import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Infographic } from 'src/app/vos/infographic/infographic';
import { AuthenticationService } from '../authentication/authentication.service';
import { map } from 'rxjs/operators';
import { CustomerResourceService } from '../customer-resource.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class InfographicsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class InfographicsService extends CustomerResourceService<Infographic> {

  public endpoint = 'infographics';
  public data_key = 'infographic';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Infographic, http, authService);
  }
  gallery(filter: string, pageId: number, mediaType: string, filterParams?): Observable<any> {
    let params: Record<string, string> = {
      'page': pageId.toString(),
      'q[tags_name_eq]': filter,
    };

    // Apply additional filters from filterParams if provided
    if (filterParams) {
      // Sorting Logic
      if (filterParams.sortOrder) {
        // Use sorting based on the posts model
        params['q[s]'] = filterParams.sortOrder === 'recent' ? 'posts.created_at desc' : 'posts.created_at asc'; // Adjust sorting order
        params['q[posts_customer_id_eq]'] =  String(this.customer_id);
      } else {
        // params['q[s]'] = 'posts.created_at desc'; // Default sorting by posts created_at
      }

      // New Posts Filter
      if (filterParams.newPosts) {
        params['q[posts_posted_at_not_null]'] = 'true'; // Include only infographics with posted posts
      }

      // Check for posted status
      if (filterParams.postedStatus) {
        if (filterParams.postedStatus === 'posted') {
          // tslint:disable-next-line:max-line-length
          params['q[branded_posts_customer_id_or_posts_customer_id_eq]'] = String(this.customer_id); // Filter infographics with posted posts
          params['q[posts_posted_at_or_branded_posts_posted_at_not_null]'] = 'true'; // Filter infographics with posted posts
        } else if (filterParams.postedStatus === 'not_posted') {
          params['q[branded_posts_customer_id_and_posts_customer_id_not_eq]'] =  String(this.customer_id);
        } else if (filterParams.postedStatus === 'scheduled') {
          // params['q[posts_scheduled_for_not_null]'] = 'true'; // Filter infographics with scheduled posts
          // tslint:disable-next-line:max-line-length
          params['q[branded_posts_customer_id_or_posts_customer_id_eq]'] = String(this.customer_id); // Filter infographics with posted posts
          params['q[posts_scheduled_for_or_branded_posts_scheduled_for_not_null]'] = 'true'; // Filter infographics with posted posts
        }
      }

      // Additional filters
      if (filterParams.postedAfter) {
        // tslint:disable-next-line:max-line-length
        params['q[posts_posted_at_or_branded_posts_posted_at_gteq]'] = String(filterParams.postedAfter); // Filter infographics with posts posted after a date
      }

      if (filterParams.postedBefore) {
        // tslint:disable-next-line:max-line-length
        params['q[posts_posted_at_or_branded_posts_posted_at_lteq]'] = String(filterParams.postedBefore); // Filter infographics with posts posted before a date
      }

      // Filter by media type if provided
      if (filterParams.mediaType) {
        if (filterParams.mediaType === 'image')
        {
          params['q[media_type_eq]'] = String(0);
        } else {
          params['q[media_type_eq]'] = String(1);
        }
      }

      // Filter by media type if provided
      if (filterParams.branded) {
        if (filterParams.branded === 'yes')
        {
          params['q[aspect_ratio_not_null]'] = 'true';
        } else {
          params['q[aspect_ratio_blank]'] = 'true';
        }
      }

    }

    return super.listFrom('infographics', params).pipe(
      map(resp => {
        // Ensure uniqueness based on content ID, while keeping the original structure
        const uniqueContent = resp.data.filter((content, index, self) =>
          index === self.findIndex((c) => c.id === content.id)
        );

        return {
          ...resp, // Spread the rest of the response data
          data: uniqueContent, // Replace the data with unique content
        };
      })
    );

  }




  tags(): Observable<any> {
    return super.listTags('infographics/tags');
  }
}

