import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { Content } from 'src/app/vos/content/content';
import { InfographicsService } from 'src/app/services/infographics/infographics.service';
import { Infographic } from 'src/app/vos/infographic/infographic';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import {HttpParams} from '@angular/common/http';

interface PageInfo {
  total_entries: number;
  per_page: number;
  current_page: number;
}
@Component({
  selector: 'app-infographics',
  templateUrl: './infographics.component.html',
  styleUrls: ['./infographics.component.scss']
})
export class InfographicsComponent implements OnInit {
  // @ViewChild('categorySelector') categorySelector: MatSelect;
  customer: Customer;
  infographics: Infographic[];
  pageInfo: any;
  content: Content[] = [];
  tabs = ['Branded', 'Unbranded'];
  currentTab = 0;
  loading = false;
  selectedCategory = '';
  categories = [];
  currentPage = 1;
  selectedCategories: string[];

  filters = {
    newPosts: false,
    postedStatus: null,
    postedAfter: null,
    postedBefore: null,
    branded: null,
    sortOrder: '',
    mediaType: ''
  };
  // categorySelectionOutput: Observable<MatOptionSelectionChange>;
  constructor(
    private authService: AuthenticationService,
    private infoService: InfographicsService,
    private breadcrumService: BreadCrumService
  ) { }

  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.loadTags();
    this.loadGallery(this.currentPage);
    this.breadcrumService.set_breadcrum();
  }
  loadTags() {
    this.infoService.tags().subscribe(resp => {
      this.categories = resp.tags[0].children;
    });
  }
  loadGallery(page, params?) {
    this.loading = true;
    this.infoService.gallery(this.selectedCategory, page, `${this.currentTab === 0 ? '1' : '0'}`, this.filters).subscribe(resp => {
      if (resp) {
        this.infographics = resp.data;
        this.pageInfo = { total_entries: resp.total_entries, per_page: resp.per_page, current_page: resp.current_page };
        this.filterContent();
      }
    }, err => {
      this.loading = false;
    });
  }

  changeIndex(index) {
    this.currentTab = index;
    this.currentPage = 1;
    this.loadGallery(this.currentPage);
  }

  filterContent() {
    this.content = this.infographics?.map(i => this.contentFor(i));
    this.loading = false;
  }

  contentFor(i: Infographic): Content {
    return new Content({
      id: i.id,
      url: i.url,
      status: 'ready',
      caption: i.caption,
      category: 'IF',
      style: '',
      thumbnail: i.thumbnail,
      media_type: i.media_type,
      aspect_ratio: i.aspect_ratio,
      contentable_id: this.customer?.id,
      contentable_type: 'Customer'
    });
  }
  handleChange(checked: boolean, category: string): void {
    this.selectedCategory = checked ? category : '';
    this.loadGallery(this.currentPage);
  }

  applyFilters() {
    this.loadGallery(this.currentPage, this.filters);
  }
  clearFilters(){
    this.filters = {
      newPosts: false,
      postedStatus: null,
      postedAfter: null,
      postedBefore: null,
      branded: null,
      sortOrder: '',
      mediaType: ''
    };
    this.selectedCategory = '';
    this.loadGallery(this.currentPage, this.filters);
  }
}
