<div #swiperContainer class="swiper-container">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let url of urls">
            <img [src]="url" alt="Image" />
            <span *ngIf="showRemoveButton" (click)="removeImage(url, $event)"  class="btnClose" nz-icon nzType="close" nzTheme="outline"></span>
        </div>
    </div>

    <div [hidden]="urls.length <= 1" #nextButton class="swiper-button-next-img">
        <span nz-icon nzType="right" nzTheme="outline"></span>
    </div>
    <div [hidden]="urls.length <= 1" #prevButton class="swiper-button-prev-img">
        <span nz-icon nzType="left" nzTheme="outline"></span>
    </div>
</div>
