<div class="container">
  <div class="header">
    <div class="header-title">
      {{ content.display_name || content.content_attributes.display_name }}
    </div>
    <div class="header-subtitle">
      <div class="social-icon">
        <span class="icon-wrapper" *ngIf="checkSocial('facebook')">
          <img class="box-icon" src=".././../../assets/icons/facebook.png" alt="" />
          <span class="social-name">Facebook</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('linkedin')">
          <img class="box-icon" src=".././../../assets/icons/linkedin.png" alt="" />
          <span class="social-name">Linkedin</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('instagram')">
          <img class="box-icon" src=".././../../assets/icons/instagram.png" alt="" />
          <span class="social-name">Instagram</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('google')">
          <img class="yt-box-icon" src=".././../../assets/icons/google.png" alt="" />
          <span class="social-name">Google</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('youtube')">
          <img class="box-icon" src=".././../../assets/icons/youtube.png" alt="" />
          <span class="social-name">Youtube</span>
        </span>
        <span class="icon-wrapper" *ngIf="checkSocial('tiktok')">
          <img class="box-icon" src=".././../../assets/icons/tiktok.png" alt="" />
          <span class="social-name">Tiktok</span>
        </span>
      </div>
      <div class="subtitle-time">
        <span class="subtitle-time-icon">
          <span nz-icon nzType="calendar" nzTheme="outline" style="font-size: 24px;"></span>
        </span>
        <span class="subtitle-time-text">
          {{ content.created_at | date: 'EEE, MMM d' }}
        </span>
      </div>
    </div>
  </div>

  <div class="content-preview">
    <div class="content-preview-image">
      <div *ngIf="(content.content_attributes?.status !== 'rendering') || (content.post?.status !== 'rendering')"
        class="image-wrapper">
        <!-- Image Content -->
        <img *ngIf="((content.content_attributes?.media_type === 'image') || (content.media_type === 'image')) && !isShowSlide " [src]="content.content_attributes?.url || content.url"
          [alt]="content.post.caption" loading="lazy" />

        <!-- Carousel Content -->
        <img *ngIf="(content.content_attributes?.media_type === 'carousel') || (content.media_type === 'carousel')" [src]="content.content_attributes?.fileURLs[0] || content?.fileURLs[0]"
          [alt]="content.post.caption" loading="lazy" />

        <!-- Video Content -->
        <video *ngIf="(content.content_attributes?.media_type === 'video') || (content.media_type === 'video')" autoplay loop [muted]="true" controls
          playsinline>
          <source [src]="content.content_attributes?.url || content?.url" />
          Your browser does not support the video tag.
        </video>
        
        <!-- Slide Content  -->
        <app-swiper-images *ngIf="isShowSlide"  [imageUrls]="content.custom_image_urls || content.content_attributes?.custom_image_urls"></app-swiper-images>   
      </div>
    </div>

    <div class="content-preview-details">
      <div class="status">
        <span class="title">Status:</span>
        <div *ngIf="content.post.status === 'successful'" class="status-container">
          <span nz-icon nzType="check-circle" nzTheme="fill"></span>
          <span>{{content.post.status}}</span>
        </div>
        <div *ngIf="content.post.status === 'scheduled'" class="status-scheduled-container">
          <span nz-icon nzType="clock-circle" nzTheme="fill"></span>
          <span>{{content.post.status}}</span>
        </div>
        <div *ngIf="content.post.status === 'failed'" class="status-failed-container">
          <span nz-icon nzType="close-circle" nzTheme="fill"></span>
          <span>{{content.post.status}}</span>
        </div>
        <div *ngIf="content.post.status === 'posting'" class="status-posting-container">
          <span nz-icon nzType="loading" nzTheme="outline"></span>
          <span>{{content.post.status}}</span>
        </div>
      </div>

      <div class="schedule">
        <span class="title">Scheduled:</span>
        <div style="display: flex; flex-wrap: wrap;gap:10px">
          <div class="datetime-container">
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            <span>{{ formatDate(content.post.scheduled_for) || '-' }}</span>
          </div>
          <div class="datetime-container">
            <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
            <span>{{formatTime(content.post.scheduled_for) || '-'}}</span>
          </div>
        </div>
      </div>

      <div class="posted">
        <span class="title">Posted:</span>
        <div style="display: flex; flex-wrap: wrap; gap:10px">
          <div class="datetime-container">
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            <span>{{formatDate(content.post.posted_at) || '-'}}</span>
          </div>
          <div class="datetime-container">
            <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
            <span>{{formatTime(content.post.posted_at) || '-'}}</span>
          </div>
        </div>
      </div>

      <div class="description">
        <span class="title">Description:</span>
        <div class="text">
          <span [innerHTML]="sanitizedContent">
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="button">
      <button
        nz-button
        nzType="primary"
        class="btnDelete"
        (click)="handleDeletePost()"
      >
        <span nz-icon nzType="delete"></span>
        Delete
      </button>
      <button *ngIf="!isEditableCaption()" nz-button nzType="primary" class="btnConfirm" (click)="close()">
        <span nz-icon nzType="check-circle"></span>
        Confirm
      </button>
      <button *ngIf="isEditableCaption()" nz-button nzType="primary" class="btnEdit" (click)="openEditCaptionModal(content.post)">
        <span nz-icon class="edit-icon" nzType="edit" nzTheme="fill"></span>
        Edit
      </button>
    </div>
  </div>
</div>